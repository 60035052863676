.modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.isShow {
    opacity: 1;
    pointer-events: visible;
}

.modal-content {
    border-radius: 8px;
    background-color: var(--light-green);
    width: calc(min(850px, 90%));
    max-height: 95%;
    overflow: auto;
    transform: translateY(-200px);
    transition: all 0.3s ease-in-out;
}

.modal-content::-webkit-scrollbar {
  /* Show scrollbar for Chrome, Safari and Opera */
  display: inline;
  -ms-overflow-style: inline;  /* IE and Edge */
  scrollbar-width: inline;  /* Firefox */
}

.isShow .modal-content {
    transform: translateY(0);
}

.modal-header {
    display: flex;
    padding: 10px;
    background-color: var(--bright-green);
    justify-content: center;
}

.modal-title {
    margin: 0px;
    color: var(--dark-green);
    font-style: var(--main-font);
}

.modal-body {
    padding: 10px;

}

.modal-footer {
    display: flex;
    padding: 10px;
    justify-content: flex-end;
}

.modal-save {
    margin-left: 1rem;
}