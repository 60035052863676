.adj-display {
    display: flex;
    list-style: none;
    flex-direction: column;
    flex-wrap: nowrap;
    font-size: small;
}

.adj-list-element {
    color: var(--dark-green);
}