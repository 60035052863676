.grid-container {
  display: grid;
  left: 60px;
  align-items: center;
  justify-items: center;
  gap: 0 0;
  position: absolute;
  pointer-events: none;
  width: calc(min(100%, 1500px));
  height: calc(min(100%, 650px));
}

#primary_circle {
  z-index: 13;
  grid-row: 2;
  grid-column: 2;
}

.scrollable::-webkit-scrollbar {
  /* Show scrollbar for Chrome, Safari and Opera */
  display: inline;
  -ms-overflow-style: inline; /* IE and Edge */
  scrollbar-width: inline; /* Firefox */
}

#top_circle {
  grid-row: 1;
  grid-column: 1 / span 3;
}

#bottom_circle {
  grid-row: 3;
  grid-column: 2;
}

#right_circle {
  grid-row: 2;
  grid-column: 3;
}

#left_circle {
  grid-row: 2;
  grid-column: 1;
}

#bottom_circle {
  grid-row: 3;
  grid-column: 2;
}

#context_circle {
  position: absolute;
  top: 50%;
  right: 26%;
  grid-row: 3;
  grid-column: 2;
  width: 500px;
  border-radius: 25px;
  z-index: 20;
  display: none;
  cursor: default;
}

#icon-space {
  position: absolute;
}

#lightboxBottom,
#lightboxTop {
  z-index: 4;
  position: absolute;
  height: 50%;
  width: 100%;
  top: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
#lightboxBottom {
  top: 50%;
}

#hide_scroll {
  overflow: hidden;
}

#show_scroll {
  overflow: visible;
}

#lightbox_img {
  filter: brightness(0);
  padding-top: 20%;
  margin: auto;
  text-align: center;
  display: block;
  margin-top: 0px;
  margin-bottom: -25px;
  height: auto;
  width: auto;
  max-height: 5vh;
  max-width: 4vw;
}

.lightbox_title {
  margin: auto;
  text-align: center;
  font-size: 20px;
  overflow: auto;
}

.outer_indicators {
  position: absolute;
  right: 8px;
  text-align: right;
  font-size: 24px;
  padding-right: 20px;
  z-index: 20;
}

.outer_indicators > p {
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.circle {
  z-index: 5;
  display: flex;
  justify-self: center;
  flex-direction: column;
  background-color: white;
  height: calc(min(180px, 30vmin));
  aspect-ratio: 1/1;
  border-radius: 90px;
  border-color: black;
  transition: all 0.3s ease-in-out;
  border: solid;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.center_column {
  grid-column: 2;
}

.active {
  opacity: 1;
  pointer-events: visible;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.isShow {
  opacity: 1;
  pointer-events: visible;
}

.small-circle {
  z-index: 10;
  width: calc(min(40px, 7.5vmin));
  aspect-ratio: 1/1;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

#line-canvas {
  z-index: 4;
  position: absolute;
  pointer-events: none;
}

#lines {
  stroke: rgb(255, 255, 255);
  stroke-width: 2;
  pointer-events: visible;
  cursor: pointer;
}

#targetLink {
  position: relative;
  text-align: center;
  margin-bottom: 5px;
}

#indicatorLink {
  position: relative;
  text-align: center;
  margin-bottom: 5px;
}
