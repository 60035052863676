/* --- Header Styles --- */
.admin-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: 0;
}

.admin-header {
    background-color: var(--dark-green);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    height: 8vh;
}

.admin-title {
    color: var(--white);
    font-size: x-large;
    font-family: 'Poppins', sans-serif;
}

.admin-back-button, .admin-help-button {
    background-color: var(--light-green);
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: var(--dark-green);
    border-radius: 8px;
    border: 0;
    font-size: medium;
    font-weight: bold;
    max-height: fit-content;
    max-width: fit-content;
}

.admin-help-button {
    opacity: 0;
    pointer-events: none;
}

.admin-back-button:hover, .admin-help-button:hover {
    color: var(--bright-green);
}

/* --- Page Styles --- */

.admin-body {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    height: 92vh;
    padding: 15px;
    overflow: hidden;
}

.admin-left-panel {
    display: flex;
    align-items: center;
    flex-direction: column;

    background-color: var(--light-green);
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    min-width: min-content;
    width: 50%;
    height: 93%;

    padding: 20px;

    overflow: auto;
}

.admin-right-panel {
    overflow: hidden;
    width: 50%;
    min-height: 95%;
    margin: 15px;
    margin-top: 0px;
}

.admin-barchart-container, .admin-io-container {
    background-color: var(--light-green);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.admin-barchart-container {
    padding: 0px;
    margin-bottom: 15px;
    width: 100%;
    height: 73vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-io-container {
    height: 11vh;
    width: 99%;
    margin: 1vh;
}

.admin-upload-form {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.admin-upload-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.admin-upload-input:before{
    content: 'Select some files';
    display: inline-block;
    background: linear-gradient(to top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px var(--white);
    font-weight: 700;
    font-size: 10pt;
}

.admin-upload-input:focus + label {
    outline: 0px dotted var(--black);
    outline: -webkit-focus-ring-color auto 5px;
}

.admin-upload-label {
    font-size: 1rem;
    height: 8vh;
    width: calc(min(70%, 220px));
    font-weight: 700;
    color: var(--white);
    background-color: #e45b01;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}

.admin-upload-label:hover {
    background-color: #b24601;
}

.admin-upload-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-upload-button {
    font-size: 1rem;
    height: 8vh;
    width: calc(min(15%, 95px));
    font-weight: 700;
    border: 0;
    color: var(--dark-green);
    background-color: var(--bright-green);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}

.svgClass {
    max-height: 80vh;
}

.admin-upload-button:hover {
    background-color: #7cab32;
}

.admin-report-button {
    font-size: 1rem;
    height: 5vh;
    max-width: 20vw;
    font-weight: 700;
    border: 0;
    color: var(--dark-orange);
    background-color: var(--bright-orange);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}

.admin-report-button:hover {
    color: var(--bright-orange);
    background-color: var(--light-orange);
}

/* Custom Scroll Bar */
::-webkit-scrollbar {
    width: 10px;
    background-color: var(--white);
}

::-webkit-scrollbar-track {
    background: var(--white);
    border-radius: 8px;
    overflow: auto;
}

::-webkit-scrollbar-thumb {
    background: var(--dark-green);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--bright-green);
}