@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  font-family: "Poppins", sans-serif;
}

.flex-container {
  /* We first create a flex layout context */
  display: flex;

  /* Then we define the flow direction 
      and if we allow the items to wrap 
    * Remember this is the same as:
    * flex-direction: row;
    * flex-wrap: wrap;
    */
  /*flex-flow: row wrap;
  
  /* Then we define how is distributed the remaining space */
  justify-content: space-around;

  padding: 0;
  padding-top: 1vh;
  /* padding-inline: 30%; */
  list-style: none;
  background-color: #e3e3e3;
  /*padding-inline: 60px;*/
  width: 80%;
  padding-inline: 10%;
  flex-wrap: wrap;
}

.flex-container > li {
  display: flex;
  flex-direction: row;

  max-width: calc(max(19vw, 250px));
  min-width: 250px;
}

.title {
  font-family: var(--title-font);
}

.subtitle {
  font-family: var(--subtitle-font);
  font-weight: 600;
}

.flex-item {
  background: #e3e3e3;
  padding: 5px;
  /*width: 178.5px;*/
  height: 100px;
  margin-top: 10px;
  color: black;
  font-size: 3em;
  text-align: left;
  margin-block-start: 0;
  display: block;
  padding-inline: 10px;
}

.item1 {
  color: #f54287;
  font-size: 15px;
  margin-top: 20px;
  margin-inline: 0;
  /*padding-left: 20px;*/
  /* width: 200px; */
  white-space: nowrap;
  overflow: visible;
}

.pStyle > p {
  font-size: 10px;
  color: #616161;
  width: fit-content;
  height: fit-content;
}

.headStyle > h1 {
  font-size: 15px;
  color: #616161;
}

.imageDiv {
  margin-top: 15px;
  padding: 0%;
}

.image {
  width: 30px;
  height: 30px;
}

.empty {
  width: 150px;
}

.line {
  width: 80%;
  color: #616161 noshade;
  size: 5px;
}

.button {
  background-color: #4caf50; /* Green */
  /* opacity: 0.85; */
  border: none;
  color: white;
  padding: 30px 64px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 20px;
  border-radius: 5px;
  margin: 2%;
}

::-webkit-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#root {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
