@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
*{
    font-family: 'Poppins', sans-serif;
    
}

:root {
  /* Colour Palette */
  --dark-green: #477C3C;
  --bright-green: #8FC53A;
  --light-green: #D2E9AF;
  
  --extra-dark-blue: #1C525E;
  --dark-blue: #297C8E;
  --bright-blue: #3AADC6;
  --light-blue: #B0EBF1;
  
  --dark-orange: #B84900;
  --bright-orange: #FF7518;
  --light-orange: #FFC299;
  
  --white: #fff;
  --black: #000;

  /* Fonts */
  --main-font: 'Poppins', sans-serif;
  --secondary-font: "JetBrains Mono";
  --backup-font: 'Arial';
  --title-font: 'Poppins ExtraBold 800' sans-serif;
  --subtitle-font: 'Poppins SemiBold 600' sans-serif;
}

body {
  font-size: 20px;
}

.users {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.user {
  padding: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.user > div {
  margin-top: 1rem;
  color: #ccc;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
