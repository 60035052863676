.add-sector-form {
    background-color: aqua;
    display: grid;
    grid-template-columns: [labels] auto [controls] 1fr;
    grid-auto-flow: row;
    grid-gap: .8em .5em;
    background-color: var(--light-green);
    padding: 1.2em;
    width: 36em;
}

.add-sector-form > label {
    grid-column: labels;
    grid-row: auto;
}

.add-sector-form > input,
.add-sector-form > textarea,
.add-sector-form > button {
    grid-column: controls;
    grid-row: auto;
    padding: .4em;
    border: 0;
}

.add-sector-form > textarea {
    min-width: 350px;
    max-width: 600px;
    max-height: 500px;
}

.add-sector-form > button {
    background: var(--dark-green);
    color: var(--light-green);
}

.add-sector-form > textarea {
    min-height: 3em;
}

.my-custom-select {
    min-width: 350px;
    max-width: 600px;
    max-height: 500px;
    font-size: 14px;
    border: none;
    border-radius: none;
}
  
.my-custom-select .react-select__control {
    background-color: #fff;
    color: #333;
}

.my-custom-select .react-select__option--is-selected {
    background-color: #f7f7f7;
    color: #333;
}
